import React from "react"
import FancyTitle from "./../fancy-title/fancyTitle"

import "./videoCard.scss"

var VideoCard = props => (
  <div className={`video-card-component ${props.className || ""}`}>
    <Video hide={!props.right} {...props} />
    <Body {...props} />
    <Video class="bottom" hide={props.right} {...props} />
  </div>
)

var Body = props => (
  <div className="body">
    <FancyTitle title={props.title} subtitle={props.subtitle} />
    <p>{props.description}</p>
  </div>
)
/* eslint-disable */
var Video = props => (
  <div
    className={`video-wrap ${props.class || ""} ${props.hide ? "hide" : ""}`}
  >
    <div className="video">
      <iframe
        src={props.link}
        frameBorder="0"
        allow="fullscreen"
        allowFullScreen
      />
    </div>
  </div>
)

export default VideoCard
