import React from "react"

import PageBody from "../components/page-body/pageBody"
import SEO from "../components/seo"

import Banner from "../components/banner/banner"
import bg from "../images/banners/recorded-tutorials.jpg"

import "./tutorials.scss"
import BorderTitle from "../components/border-title/borderTitle"
import VideoCard from "../components/video-card/videoCard"
import { axios } from "../utils/utils"
import Loader from "../components/loader/loader"

class Tutorials extends React.Component {
  constructor() {
    super()
    this.state = {
      videos: [],
      loading: true,
    }
  }

  componentDidMount() {
    axios
      .get("/videos-api/tutorials")
      .then(result => this.setState({ videos: result.data, loading: false }))
      .catch(err => {
        this.setState({ loading: false })
        console.log(err)
      })
  }

  render() {
    return (
      <>
        <SEO title="Home" keywords={["gatsby", "application", "react"]} />
        <div className="tutorials-page">
          <Banner
            src={bg}
            title="Tutorials"
            subTitle=""
            height="27.5rem"
            backgroundPosY="80%"
          />
          <PageBody className="page">
            {this.state.loading ? (
              <div className="page-loader">
                <Loader />
              </div>
            ) : this.state.videos && this.state.videos.length > 0 ? (
              this.state.videos.map((video, index) => (
                <VideoCard
                  key={video.title}
                  right={index % 2 === 0}
                  title={video.title}
                  subtitle={video.subtitle}
                  description={video.description}
                  link={video.videoUrl}
                />
              ))
            ) : (
              <div>Sorry, no tutorials available yet</div>
            )}
          </PageBody>
        </div>
      </>
    )
  }
}

export default Tutorials
